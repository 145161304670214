import './ServiceAccessPoints.style.scss'

import {useCallback, useEffect, useMemo, useState} from 'react'
import {TAccessScheduleFields} from '../../../components/AccessSchedule/AccessSchedule'
import {addDays, addHours} from 'date-fns'
import useAccessPoints, {TAccessPointsValue} from '../useAccessPoints'
import {AccessScheduleTypes} from '../../../data/graphql/mutations/lock/types'
import usePinTransactions from '../../../hooks/data/usePinTransactions'
import useUserPersonId from '../../../hooks/useUserPersonId'
import {isCommonAreaBuilding} from '../../../functions/devices.function'
import useUnitsDevices from '../../../hooks/data/useAccessPointsDevices'
import {DeviceTypes} from '../../../data/graphql/queries/common/types'
import useCommonAreasVendorUnits from './useCommonAreasVendorUnits'

type TAccessPointSchedule = {
  data: TAccessScheduleFields
  propertyId: string
  buildingId: string
  unitId: string
}

type TStructure = {
  propertyId?: string
  buildingId?: string
  unitId?: string
}

const accessPointSchedule: TAccessPointSchedule = {
  unitId: '',
  buildingId: '',
  propertyId: '',

  data: {
    accessType: 'app',
    accessScheduleType: AccessScheduleTypes.ALWAYS,
    startDate: new Date(),
    endDate: addHours(new Date(), 1),
    weekDays: [],

    startTime: {
      dayTime: 'am',
      hours: 9,
      minutes: 0,
    },
    endTime: {
      dayTime: 'am',
      hours: 11,
      minutes: 0,
    },
  },
}

type TProps = {
  personId: number
  defaultSchedule: TAccessScheduleFields
  value: TAccessPointsValue<TAccessScheduleFields>
  isVendorIdentityCreated: boolean
  onChange: (value: TAccessPointsValue<TAccessScheduleFields>) => void
}

const useServiceAccessPoints = ({
  defaultSchedule,
  value,
  personId,
  isVendorIdentityCreated = false,
  onChange,
}: TProps) => {
  const initiatorPersonId = useUserPersonId()
  const vendorCommonAreaUnits = useCommonAreasVendorUnits()
  const transactions = usePinTransactions('', {
    initiatorPersonId,
    targetPersonId: personId,
  })
  const unitsDevices = useUnitsDevices(
    [DeviceTypes.YALE_622, DeviceTypes.BRIVO, DeviceTypes.YALE_ASSURE_2],
    {
      isDeviceInstalled: true,
    },
  )

  const unitFilter = useCallback(
    ({
      unitId,
      propertyId,
      unitNumber,
      isCommonArea,
    }: {
      unitId: string
      propertyId: string
      unitNumber: string
      isCommonArea: boolean
    }) => {
      let hasCommonAreaGroupAccess = true

      if (isCommonArea) {
        hasCommonAreaGroupAccess = vendorCommonAreaUnits.data[propertyId]?.includes(
          unitNumber.toLowerCase(),
        )
      }

      return hasCommonAreaGroupAccess && !!unitsDevices.data[unitId]
    },
    [unitsDevices.data, vendorCommonAreaUnits.data],
  )

  const {
    property: activeProperty,
    building: activeBuilding,
    properties: propertiesList,
    isBuildingSelected,
    isPropertySelected,

    toggleUnit,
    toggleProperty,
    toggleBuilding,
    selectProperty,
    selectBuilding,
    updateCustomData: setCustomSchedule,
  } = useAccessPoints<TAccessScheduleFields>({
    value,
    defaultData: defaultSchedule,
    unitFilter,
    getStructureSpecificData,
    onChange,
  })

  const [isSchedulOpen, setScheduleOpenFlag] = useState(false)
  const [schedule, setSchedule] = useState<TAccessPointSchedule>(accessPointSchedule)

  useEffect(() => {
    if (propertiesList?.length) {
      selectProperty(propertiesList[0])

      if (propertiesList[0].buildingsByPropertyId.nodes.length) {
        selectBuilding(propertiesList[0].buildingsByPropertyId.nodes[0])
      }
    }
  }, [propertiesList, selectBuilding, selectProperty])

  const closeScheduleModal = () => {
    setScheduleOpenFlag(false)
  }

  const commonAreaAppAccessAllowed = useMemo(() => {
    console.log({isVendorIdentityCreated})

    if (isVendorIdentityCreated) {
      return true
    }

    const isCommonAreaAppAccessAssigned = Object.values(value).some(property => {
      const buildings = property?.buildings || {}

      if (property?.data?.accessType === 'app') {
        return true
      }

      return Object.values(buildings).some(building => {
        const units = building?.units || {}

        if (building?.data?.accessType === 'app') {
          return true
        }

        return Object.values(units).some(unit => unit?.accessType === 'app')
      })
    })

    return isCommonAreaAppAccessAssigned
  }, [isVendorIdentityCreated, value])

  function getStructureSpecificData(
    structure: TStructure,
    sourceData: TAccessScheduleFields,
  ) {
    const data = structuredClone(sourceData)

    const targetProperty = propertiesList.find(
      ({propertyId}) => propertyId === structure.propertyId,
    )
    const targetBuilding = targetProperty?.buildingsByPropertyId.nodes.find(
      ({buildingId}) => buildingId === structure.buildingId,
    )

    if (!isCommonAreaBuilding(targetBuilding)) {
      return data
    }

    if (data.accessType === 'app' && !commonAreaAppAccessAllowed) {
      data.accessType = 'pin'
    }

    if (data.accessScheduleType === AccessScheduleTypes.RECURRING) {
      data.accessScheduleType = AccessScheduleTypes.TEMPORARY
      data.startDate = new Date()
      data.endDate = addDays(new Date(), 1)
      data.weekDays = []
    }

    return data
  }

  const openScheduleModal = (structure: TStructure) => {
    const {propertyId, buildingId, unitId} = structure

    const getStructureSchedule = () => {
      const property = value[propertyId || activeProperty?.propertyId || '']
      const building = property?.buildings[buildingId || activeBuilding?.buildingId || '']
      const unit = building?.units?.[unitId || '']

      if (unitId && building && property) {
        return unit || building.data || property.data || defaultSchedule
      } else if (building && property) {
        return building.data || property.data || defaultSchedule
      } else if (property) {
        return property.data || defaultSchedule
      }

      return defaultSchedule
    }

    setSchedule(prev => ({
      ...prev,
      data: getStructureSchedule(),
      unitId: unitId || '',
      buildingId: buildingId || '',
      propertyId: propertyId || '',
    }))

    setScheduleOpenFlag(true)
  }

  const applyCustomSchedule = () => {
    setCustomSchedule(schedule)
    closeScheduleModal()
  }

  const onChangeSchedule = (schedule: TAccessScheduleFields) => {
    setSchedule(prev => ({
      ...prev,
      data: schedule,
    }))
  }

  const unitsSortComparator = (unitA: string, unitB: string) => {
    const transactionA = transactions.unitTransactionsMap[unitA]
    const transactionB = transactions.unitTransactionsMap[unitB]

    const sortOrder = {
      Failed: 0,
      Pending: 1,
      undefined: 2,
    }

    return sortOrder[transactionA?.status] - sortOrder[transactionB?.status]
  }

  return {
    loading: transactions.loading || vendorCommonAreaUnits.loading,
    schedule,
    isSchedulOpen,
    property: activeProperty,
    building: activeBuilding,
    properties: propertiesList,
    isBuildingSelected,
    isPropertySelected,
    toggleUnit,
    toggleProperty,
    toggleBuilding,
    selectProperty,
    selectBuilding,
    applyCustomSchedule,
    openScheduleModal,
    closeScheduleModal,
    unitsSortComparator,
    setSchedule: onChangeSchedule,
    commonAreaAppAccessAllowed,
  }
}

export default useServiceAccessPoints
